import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Dynamic Neuromuskular Stabilization (DNS)</h1>

      <p>
        Kiropraktor Akutten Stavanger/Sandnes er en av to klinikker i Norge som er autorisert i DNS (Dynamic
        Neuromuskular Stabilization) – en behandlingsmetode som har revolusjonert fysioterapi- og kiropraktorverden. DNS
        er en måte å styrke kjernemuskler/stabiliserende muskler på, men samtidig også skape avspenning i stive muskler
        og skape normal bevegelighet i ledd. DNS er basert på den motoriske utviklingen av muskelsystemet vårt som
        primært utvikles mellom 0 og 14 måneder. Kiropraktoren bruker posisjoner og bevegelser fra denne for å få de
        ideelle forutsetningene for å kunne styrke de musklene som trenger det, skape avspenning i stive muskler, skape
        bevegelighet i stive ledd og stabilitet i overbevegelige ledd.
      </p>
    </Layout>
  )
}

export default Page
